import React, { useContext, useEffect, useState } from "react";
import { useForm } from "../../hooks/useForm";
import swal from "sweetalert";
import Input from "../../Components/Form/Input";
import { Navigate, json, useNavigate } from "react-router-dom";
import AuthContext from "../../context/authContext";
import { Base_url, uploadPhoto } from "../../Config/api/api";
import "./EditProfile.css";
import Logo from "../../Components/Logo/Logo";
import {
  requiredValidator,
  minValidator,
  maxValidator,
  emailValidator,
} from "../../validators/rules";
export default function BasicTextFields() {
  const navigate = useNavigate();
  const [doctorProfile, setDoctorProfile] = useState([]);
  const authContext = useContext(AuthContext);
  const [selectedFile, setSelectedFile] = useState({});
  const [checkCover, setCheckCover] = useState(false);
  const [users, setUsers] = useState([]);
  useEffect(() => {
    setDoctorProfile(JSON.parse(localStorage.getItem("doctorProfile")));
  }, [doctorProfile]);
  const [formStatePassword, onInputHandlerPassword] = useForm(
    {
      newPassword: {
        value: "",
        isValid: false,
      },
      lastPassword: {
        value: "",
        isValid: false,
      },
    },
    false
  );
  const setNewPassword = (event) => {
    event.preventDefault();
    const newDoctorInfo = {
      newPassword: `${formStatePassword.inputs.newPassword.value}`,
      lastPassword: formStatePassword.inputs.lastPassword.value,
    };
    if (
      formStatePassword.inputs.newPassword.isValid &&
      formStatePassword.inputs.lastPassword.isValid
    ) {
      const localStorageData = JSON.parse(localStorage.getItem("doctor"));
      fetch(`https://skinmind.it/api/v2/changePassword.php`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorageData.token}`,
        },
        body: JSON.stringify(newDoctorInfo),
      })
        .then((res) => {
          if (res.status == 401) {
            localStorage.setItem("doctor", null);
            navigate("/");
            return;
          } else {
            return res.json();
          }
        })
        .then((result) => {
          if (result.token) {
            authContext.doctorLogin({}, result.token);
          }
          if (result.status == 1) {
            swal({
              title: "Password Changed Succussfully",
              icon: "success",
              buttons: "Confirm",
            });
          } else {
            swal({
              title: "Please insert correct informations",
              icon: "error",
              buttons: "Confirm",
            }).then(() => {});
          }
        });
    } else {
      swal({
        title: "Error has been  occurred",
        icon: "error",
        buttons: "Confirm",
      }).then(() => {});
    }
  };
  const [formStateProfile, onInputHandlerProfile] = useForm(
    {
      fullname: {
        value: "",
        isValid: false,
      },
    },
    false
  );
  const [imageUrl, setImageUrl] = useState();
  const newProfile = (event) => {
    event.preventDefault();
    updateProfileApi(imageUrl);
  };
  const uploadFile = async (file) => {
    if (file == null) {
      alert("Please select an image.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("which", "image");

    try {
      let res = await fetch(`https://skinmind.it/api/${uploadPhoto}`, {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.status) {
            setImageUrl(result.data);
          } else {
            swal({
              title: "Error uploading image",
              icon: "error",
              buttons: "confirm",
            });
          }
        });
    } catch (error) {
      console.error("Error uploading image:", error);
      // Handle error, e.g., show an error message to the user.
    }
  };
  const updateProfileApi = (url) => {
    let doctorProfile={
      image:url,
      fullname:formStateProfile.inputs.fullname.value,
      email:(JSON.parse(localStorage.getItem("doctorProfile")).email)
    }
    const newDoctorInfo = {
      fullname: `${formStateProfile.inputs.fullname.value}`,
      image: url,
    };
    if (formStateProfile.inputs.fullname.isValid) {
      localStorage.setItem("doctorProfile", JSON.stringify(doctorProfile));

      const localStorageData = JSON.parse(localStorage.getItem("doctor"));
      fetch(`https://skinmind.it/api/v2/editDoctorProfile.php`, {
        method: "POST",
        body: JSON.stringify(newDoctorInfo),
        headers: {
          Authorization: `Bearer ${localStorageData.token}`,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          if (result.status) {
            swal({
              title: "profile updated",
              icon: "success",
              buttons: "confirm",
            });
          } else {
            swal({
              title: "Error while adding spot",
              icon: "error",
              buttons: "confirm",
            });
          }
        })
        .catch((error) => {
          swal({
            title: "Error while adding spot",
            icon: "error",
            buttons: "confirm",
          });
        });
    }
  };
  return (
    <div className="bg-slate-200">
      <div
        dir="ltr"
        className="container mx-auto h-svh overflow-scroll   md:overflow-hidden"
      >
        <div className="profile-section h-1/5 bg-blue-600 "></div>
        <div className="profile-section h-fit w-fit  md:-mt-32 -mt-20 -mb-20 md:-mb-32  ms-10  flex  items-center gap-5">
          <img
            alt="doctor Image"
            src={`${
              doctorProfile.image != ""
                ? doctorProfile.image
                : "https://th.bing.com/th/id/OIP.UY0H6jNLhhjKymJWT6HsPwHaHa?rs=1&pid=ImgDetMain"
            }`}
            className=" md:w-60 w-40 h-40  md:h-60 object-cover rounded-full "
          />
          <div>
            <div className="font-normal text-2xl md:text-3xl mb-5">
              <span className=" font-bold "> full name: </span>{" "}
              {doctorProfile.fullname}
            </div>
            <div className="font-normal text-2xl md:text-3xl mb-5">
              <span className=" font-bold "> email address: </span>{" "}
              {doctorProfile.email}
            </div>
          </div>
        </div>
        <div className="profile-section h-[10%] bg-slate-200 rounded-b-3xl"></div>
        <div className="grid grid-cols-11 justify-center bg-blue-300 h-max ">
          <div className=" col-span-11  md:col-span-5">
            <div className=" bg-blue-500  w-full text-2xl md:text-5xl  p-5 rounded-b-2xl text-center  font-light text-gray-300">
              Update Profile
            </div>
            <form class=" bg-blue-300 ">
              <div className="form-content p-12 space-y-5 text-center">
                <div class="family input">
                  <label class="input-title"> Profile Name</label>
                  <Input
                    type="text"
                    className="form__input shadow-2xl focus:border-blue-600 focus:border-[2px]  "
                    id="fullname"
                    element="input"
                    validations={[requiredValidator()]}
                    onInputHandler={onInputHandlerProfile}
                    placeholder="insert profile name"
                  />
                  <span class="error-message text-danger"></span>
                </div>
                <div class="email input justify-center flex bg-slate-200 flex-nowrap  rounded-3xl overflow-hidden  py-2  ">
                  <div class="file">
                    <label class="input-title bg-blue-800 px-2 py-5 text-xl  me-5 text-emerald-200">
                      profile image :{" "}
                    </label>
                    <input
                      className=" w-64"
                      type="file"
                      id="file"
                      onChange={(event) => {
                        setCheckCover(true);
                        uploadFile(event.target.files[0]);
                      }}
                    />
                  </div>
                  <span class="error-message text-danger"></span>
                </div>

                <div class="bottom-form flex justify-center items-center ">
                  <div class="submit-btn px-5 py-2   bg-slate-300  text-center rounded-2xl border border-slate-700 border-solid">
                    <input
                      type="submit"
                      value="Update Profile"
                      onClick={newProfile}
                      className="cursor-pointer"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className=" hidden  md:block md:col-span-1 w-1 mx-auto  bg-blue-500 "></div>
          <div className=" block   md:hidden md:col-span-1  w-screen mx-auto h-1  bg-blue-500 "></div>
          <div className=" md:col-span-5 col-span-11 ">
            <div className=" bg-blue-500  w-full text-2xl md:text-5xl  p-5 rounded-b-2xl text-center  font-light text-gray-300">
              Change Password
            </div>
            <form class=" bg-blue-300 ">
              <div className="form-content p-12  text-center">
                <div class="family input ">
                  <label class="input-title">newPassword</label>
                  <Input
                    type="text"
                    className="form__input shadow-2xl focus:border-blue-600 focus:border-[2px] "
                    id="newPassword"
                    element="input"
                    validations={[requiredValidator()]}
                    onInputHandler={onInputHandlerPassword}
                    placeholder="insert new password"
                  />
                  <span class="error-message text-danger"></span>
                </div>
                <div class="email input">
                  <label class="input-title">lastPassword</label>
                  <Input
                    type="text"
                    className="form__input shadow-2xl  focus:border-blue-600 focus:border-[2px] "
                    id="lastPassword"
                    element="input"
                    validations={[requiredValidator()]}
                    onInputHandler={onInputHandlerPassword}
                    placeholder="insert last password"
                  />
                  <span class="error-message text-danger"></span>
                </div>

                <div class="bottom-form flex justify-center items-center m-4">
                  <div class="submit-btn px-5 py-2   bg-slate-300  text-center rounded-2xl border border-slate-700 border-solid">
                    <input
                      type="submit"
                      value="Change Password"
                      onClick={setNewPassword}
                      className="cursor-pointer"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className=" hidden lg:block  absolute top-0 right-0 w-[100px] h-full bg-cyan-400 opacity-40 blur-[100px] rounded-full" />
        <div className=" hidden lg:block  absolute top-0 left-0 w-[100px] h-full bg-cyan-400 opacity-40 blur-[100px] rounded-full" />
      </div>
    </div>
  );
}
