export const Base_url="https://skinmind.it/api/"
export const createDoctor="v2/createDoctor.php"
export const loginDoctor="v2/loginDoctor.php"
export const loginAdmin="v2/loginAdmin.php"
export const getMyPatients="v2/getMyPatients.php"
export const getProfile="v2/getProfile.php"
export const getPatientBodySpots="v2/getPatientBodySpots.php"
export const getSpotImages="v2/getSpotImages.php"
export const getUserData="v2/getUserData.php"
export const getSicknesses="v2/getSicknesses.php"
export const getAllPatients="v2/getAllPatients.php"
export const getAllDoctor="v2/getAllDoctor.php"
export const insertSpotByDocter="v2/insertSpotByDocter.php"
export const insertSpotDataByDocter="v2/insertSpotDataByDocter.php"
export const deleteDoctor="v2/deleteDoctor.php"
export const uploadPhoto = "v1/uploadFile.php";
