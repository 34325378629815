import React, { useContext, useEffect, useState } from "react";
import { useForm } from "../../../hooks/useForm";
import swal from "sweetalert";
import Input from "../../Form/Input";
import "./AddDoctor.css";
import { Navigate, useNavigate } from "react-router-dom";
import AuthContext from "../../../context/authContext";
import {
  requiredValidator,
  minValidator,
  maxValidator,
  emailValidator,
} from "../../../validators/rules";
export default function AddDoctor() {
  const navigate=useNavigate()
  const authContext = useContext(AuthContext)
  const [users, setUsers] = useState([]);
  const [formState, onInputHandler] = useForm(
    {
      password: {
        value: "",
        isValid: false,
      },
      email: {
        value: "",
        isValid: false,
      },
      fullName: {
        value: "",
        isValid: false,
      },
    },
    false
  );
  const registerNewDoctor = (event) => {
    event.preventDefault();
    const newDoctorInfo = {
      password: `${formState.inputs.password.value}`,
      email: formState.inputs.email.value,
      fullName: formState.inputs.fullName.value,
    };
    if (
      formState.inputs.password.isValid &&
      formState.inputs.email.isValid &&
      formState.inputs.fullName.isValid
    ) {
      const localStorageData = JSON.parse(localStorage.getItem("admin"));
      fetch(
        `https://skinmind.it/api/v2/createDoctor.php?email=${formState.inputs.email.value}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorageData.token}`,
          },
          body: JSON.stringify(newDoctorInfo),
        }
      )
      
        .then((res) =>{
          if (res.status == 401) {
            localStorage.setItem("admin",null);
            navigate("/a-login");
            return;
          }else{
          return res.json()
          }
        }
        )
        .then((result) => {
        
          if(result.token){
            authContext.adminLogin({}, result.token);
          }
          if (result.status==1) {
            swal({
              title: "Doctor added Succussfully",
              icon: "success",
              buttons: "Confirm",
            });
          }else{
            swal({
              title: "Please insert correct informations",
              icon: "error",
              buttons: "Confirm",
            }).then(() => {});
          }
        });
    } else {
      swal({
        title: "Error has been  occurred",
        icon: "error",
        buttons: "Confirm",
      }).then(() => {});
    }
  };

  return (
    <>
      <div
        dir="ltr"
        class="home-content-edit  flex justify-center items-center  h-svh  bg-cyan-100"
      >
        <form class="form shadow-2xl border border-dashed border-lime-700   inline  p-20 rounded-3xl bg-emerald-200 space-y-7  text-center">
          <div class="family input">
            <label class="input-title">email</label>
            <Input
              type="text"
              className="form__password-input shadow-2xl "
              id="email"
              element="input"
              validations={[requiredValidator(), emailValidator()]}
              onInputHandler={onInputHandler}
              placeholder="insert email address"
            />
            <span class="error-message text-danger"></span>
          </div>
          <div class="email input">
            <label class="input-title">fullName</label>
            <Input
              type="text"
              className="form__password-input shadow-2xl "
              id="fullName"
              element="input"
              validations={[requiredValidator()]}
              onInputHandler={onInputHandler}
              placeholder="insert FullName"
            />
            <span class="error-message text-danger"></span>
          </div>
          <div class="name input ">
            <label class="input-title">password</label>
            <Input
              type="text"
              className="form__password-input shadow-2xl  "
              id="password"
              element="input"
              validations={[requiredValidator()]}
              onInputHandler={onInputHandler}
              placeholder="Insert password"
            />
            <span class="error-message text-danger"></span>
          </div>

          <div class="bottom-form flex justify-center items-center ">
            <div class="submit-btn px-5 py-2   bg-slate-300  text-center rounded-2xl border border-slate-700 border-solid">
              <input
                type="submit"
                value="add"
                onClick={registerNewDoctor}
                className="cursor-pointer"
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
