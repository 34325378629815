import React from "react";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import Logo from "../../../Logo/Logo";
import { useContext } from "react";
import AuthContext from "../../../../context/authContext";
import { useNavigate, useParams } from "react-router-dom";
import Pagination from "../../../Pagination/Pagination";
import {
  requiredValidator,
  minValidator,
  maxValidator,
  emailValidator,
} from "../../../../validators/rules";
import {
  Base_url,
  deleteDoctor,
  insertSpotByDocter,
} from "../../../../Config/api/api";
import DoctorBox from "../../../UserList/DoctorList";
import { Modal } from "react-bootstrap";
import UserBox from "../../../UserList/UserList";
import DoctorUserBox from "../../../UserList/DoctorUserBox";
export default function DoctorUserList() {
  const [shownUsers, setShownUsers] = useState([]);
  const [total, setTotal] = useState(9);
  const [limit, setLimit] = useState(6);
  const [users, setUsers] = useState([]);
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const params = useParams();
  console.log("params" , params);
  useEffect(() => {
    const localStorageData = JSON.parse(localStorage.getItem("admin"));

    fetch(`https://skinmind.it/api/v2/getDoctorPaitents.php?limit=${limit}&id=${params.doctorId}&page=${params.page}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorageData.token}`,
      },
    })
    .then((res) =>{
      if (res.status == 401) {
        localStorage.setItem("admin",null);
        navigate("/");
        return;
      }else{
      return res.json()
      }
    }
    )
    .then((users) => {
    if(users.token){
      authContext.adminLogin({}, users.token);
    }
    if (users.statusCode === 401) {
      localStorage.setItem("token", "");
      navigate("/", { replace: true });
      return;
    }
    console.log("users", users);
    setUsers(users.data.docs);
    setTotal(users.data.total);
  });
  } ,[params.page] );

  return (
    <>
      <div
        id="content "
        className="bg-cyan-100  h-svh  overflow-scroll -z-20 w-full"
      >
        <div class="container  mx-auto ">
 

          <div class="seven w-3/5 mx-auto my-5  md:text-5xl text-2xl p-10">
            <h1> List of doctor's Users</h1>
          </div>
          <div class="   h-full rounded-3xl home-content-users-table  home-content-users-title text-left   justify-center">
            {
              <table class=" z-10 border rounded-3xl  w-4/5 mx-auto  border-collapse bg-white text-left  text-gray-500   border-dashed border-gray-500 ">
                <thead className=" w-full  bg-gray-50  ">
                <tr className=" w-full grid lg:grid-cols-9 grid-cols-10  items-center gap-1  sm:gap-3 px-6 py-4 font-bold lg:text-xl md:text-base  text-sm align-middle">
                    <th className="font-medium text-gray-900 col-span-2  py-4">
                      BirthDay
                    </th>
                    <th className="font-medium text-gray-900 col-span-2 py-4">
                      Gender
                    </th>
                    <th className="font-medium text-gray-900 col-span-2  py-4">
                      Lastname
                    </th>
                    <th className="font-medium text-gray-900 col-span-2  py-4">
                      Firstname{" "}
                    </th>

                    <th className="font-medium text-gray-900 lg:col-span-1 col-span-2 py-4"></th>
                  </tr>
                </thead>
                <tbody className=" devide-x devide-dashed  w-full divide-y divide-gray-100 border-t border-gray-100">
                  {shownUsers.map((user) => (
                  <DoctorUserBox {...user} />
                  ))}
                </tbody>
              </table>
            }
          </div>
        </div>
        <Pagination
          items={users}
          total={total}
          itemsCount={limit}
          pathname="/p-admin/doctor/2/users"
          setShownUsers={setShownUsers}
        />
      </div>
      <div className=" hidden lg:block  absolute top-0 right-0 w-[100px] h-full bg-cyan-400 opacity-40 blur-[120px] rounded-full" />
      <div className=" hidden lg:block  absolute top-0 left-0 w-[100px] h-full bg-cyan-400 opacity-40 blur-[120px] rounded-full" />
    </>
  );
}
