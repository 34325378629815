import React, { useCallback, useEffect, useMemo, useState } from "react";
import DoctorTopbar from "../../Components/DoctorTopbar/DoctorTopbar";
import { femaleFront, femaleBack, maleBack, maleFront } from "./Src";
import { female } from "./Src";
import "./UserPage.css";
import { requiredValidator } from "../../validators/rules";
import {getSicknesses, insertSpotByDocter, insertSpotDataByDocter, uploadPhoto} from "../../Config/api/api";
import Input from "../../Components/Form/Input";
import Button from "../../Components/Form/Button";
import swal from "sweetalert";
import { useForm } from "../../hooks/useForm";
import { useRef } from "react";
//import Chart from "../../Components/Chart/Chart";
import CircleSpinner from "../../Components/CircleSpinner/CircleSpinner";
import {useNavigate, useParams} from "react-router-dom";


import {
  Base_url,
  getPatientBodySpots,
  getSpotImages,
  getUserData,
} from "../../Config/api/api";
import ChartContainer from "../../Components/Chart/Chart";
import DatePicker, { DateObject } from "react-multi-date-picker";
import BarChart from "../../Components/Chart/Chart";
import woman_front from "../../Assets/photos/weman_front.png"
import woman_back from "../../Assets/photos/woman_back.png"
import man_front from "../../Assets/photos/man_front.png"
import man_back from "../../Assets/photos/man_back.png"
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import ImageComponent from "./chart";
export default function UserPage() {
  const [addSpotCheck, setAddSpotCheck] = useState("close");
  const [userProfile, setUserProfile] = useState({});
  const [bodySide, setBodySide] = useState("front");
  const [bodySpots, setBodySpots] = useState([]);
  const [allSpots, setAllSpots] = useState([]);
  const [spotId, setSpotId] = useState();
  const [currentXAndY, setCurrentXAndY] = useState({x:0,y:0});
  const [currentImg, setCurrentImg] = useState({});
  const [bodyImage, setBodyImage] = useState(woman_front);
  const [currentImgIndex, setCurrentImgIndex] = useState();
  const [currentComment, setCurrentComment] = useState();
  const [clickedSpot, setClickedSpot] = useState();
  const params = useParams();
  const [sickness, setSickness] = useState([]);
//date handler
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const filteredData = allSpots.filter((item) => {
    const itemDate = new DateObject(item.insertTime);
    console.log(itemDate.toDays());
    return (
        startDate==null || endDate==null || (itemDate.toDays() >= startDate.toDays()) && (itemDate.toDays() <= endDate.toDays())
    );
  });
  const [isImgShow, setIsImgShow] = useState(false);
  const navigate = useNavigate();
  const onImageLoaded = () => setIsImgShow(true);
  const onInputHandler = (event) => {
    setCurrentComment(event.target.value);
  };
  const onSideClick = (side) => {
    setBodySide(side);
    setBodyPhoto(userProfile.gender,side);
  };
  const handleFileChange =  (event) => {
    console.log("file"+event.target.files[0]);
    uploadFile(event.target.files[0]);
  };
  const uploadFile = async (file) => {

    if (file==null) {
      alert('Please select an image.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('which', "image");

    try {
      let res = await fetch(`${"https://skinmind.it/api/" + uploadPhoto}`, {
        method: "POST",
        body: formData
      }).then(res=>{
        console.log(res ,"res");
        if (res.status == 401) {
          localStorage.setItem("doctor",null);
          navigate("/");
          return;
        }else{
        return res.json()
        }
      }
      )
          .then(result=>{
            if (result.status) {
              if(addSpotCheck==="open"){
                insertNewSpot(true,result.data);
              }else if(addSpotCheck==="spotClicked"){
                addSpotDataToSpotApi(result.data);
              }
            }else{
              swal({
                title: "Error uploading image",
                icon: "error",
                buttons: "confirm",
              });
            }
          })
    } catch (error) {
      console.error('Error uploading image:', error);
      // Handle error, e.g., show an error message to the user.
    }
  };
  const fileInputRef = useRef(null);
  const handleButtonClick = () => {
    // Click the file input programmatically
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const spotClicked = (spot, event) => {
    if (spotId != spot.spotId) {
      setBodySpots([]);
      setSpotId(spot.spotId);
    }
    setClickedSpot(spot.spotId);
    setAddSpotCheck("spotClicked");
    setStartDate(null);
    setEndDate(null);
  };
  const insertNewSpotApi =  (isWithPhoto,url) => {
    if(isWithPhoto){
      var body = {
        gender: userProfile.gender,
        side: bodySide,
        x: currentXAndY.x,
        y:currentXAndY.y,
        spotData:{
          image_url:url
        }
      };
    }else{
      var body = {
        gender: userProfile.gender,
        side: bodySide,
        x: currentXAndY.x,
        y:currentXAndY.y
      };
    }
    const localStorageData = JSON.parse(localStorage.getItem("doctor"));
    fetch(`${Base_url + insertSpotByDocter}?userId=${params.userid}`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorageData.token}`,
      },
    }).
    then(res =>{
      console.log(res ,"res");
      if (res.status == 401) {
        localStorage.setItem("doctor",null);
        navigate("/");
        return;
      }else{
      return res.json()
      }
    }
    )
        .then(result => {
          console.log(result);
          if (result.status) {
            if (currentImg.comment==="" || currentImg.comment===undefined){
              swal({
                title: "new spot added",
                icon: "success",
                buttons: "confirm",
              });
              const newSpot = { id: result.data,x:body.x,y:body.y,gender:body.gender,side:body.side };
              setBodySpots((prevBodySpots) => [...prevBodySpots, newSpot]);
              setAddSpotCheck("close");
              setCurrentXAndY({x:0,y:0});
              setClickedSpot(null);
              if(isWithPhoto){
                setSpotId(null);
              }
            }else{
              swal({
                title: "Error while adding spot",
                icon: "error",
                buttons: "confirm",
              });
            }
          }
        }).catch(error=>{
      console.log(error.message)
    });
  };

  const addSpotDataToSpotApi =  (url) => {
    const body = {
      image_url:url
    };

    const localStorageData = JSON.parse(localStorage.getItem("doctor"));
    fetch(`${Base_url + insertSpotDataByDocter}?spotId=${spotId}&userId=${params.userid}`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorageData.token}`,
      },
    }).
    then(res => {
      console.log(res ,"res");
      if (res.status == 401) {
        localStorage.setItem("doctor",null);
        navigate("/");
        return;
      }else{
      return res.json()
      }
    }
    )
        .then(result => {
          console.log(result);

          if (result.status) {
              swal({
                title: "new photo added to spot",
                icon: "success",
                buttons: "confirm",
              });
              //setClickedSpot("-1");
            setSpotId(null);
            setSpotId(clickedSpot);

          }else{
              swal({
                title: "Error while adding photo",
                icon: "error",
                buttons: "confirm",
              });
            }
        }).catch(error=>{
      console.log(error.message)
    });
  };

  const insertNewSpot= (isWithPhoto,url) => {
    if(isWithPhoto){
      if(addSpotCheck==="open" && url!==""){
        insertNewSpotApi(isWithPhoto,url);
      }
    }else{
      if(addSpotCheck==="open"){
        insertNewSpotApi(isWithPhoto,"");
      }
    }
  }

  useEffect(() => {
    const localStorageData = JSON.parse(localStorage.getItem("doctor"));

    fetch(`${Base_url + getUserData}?id=${params.userid}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorageData.token}`,
      },
    })
      .then((res) => {
        console.log(res ,"res");
        if (res.status == 401) {
          localStorage.setItem("doctor",null);
          navigate("/");
          return;
        }else{
        return res.json()
        }
      }
      )
      .then((result) => {
        setUserProfile(result.data.profile);
        setBodyPhoto(result.data.profile.gender,"front");
        console.log("userProfile", result.data.profile);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    const localStorageData = JSON.parse(localStorage.getItem("doctor"));

    fetch(
      `${Base_url + getPatientBodySpots}?id=${params.userid}&gender=${
        userProfile.gender
      }&side=${bodySide}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorageData.token}`,
        },
      }
    )
      .then((res) => {
        console.log(res ,"res");
        if (res.status == 401) {
          localStorage.setItem("doctor",null);
          navigate("/");
          return;
        }else{
        return res.json()
        }
      }
      )
      .then((result) => {
        setBodySpots(result.data.spots);
      })
      .catch((error) => console.error(error));
  }, [userProfile, bodySide, spotId]);

  useEffect(() => {
    const localStorageData = JSON.parse(localStorage.getItem("doctor"));

    fetch(
        `${Base_url + getSpotImages}?id=${params.userid} ${
            spotId != null ? `&spotId=${spotId}` : null
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorageData.token}`,
          },
        }
    )
        .then((res) =>{
          console.log(res ,"res");
          if (res.status == 401) {
            localStorage.setItem("doctor",null);
            navigate("/");
            return;
          }else{
          return res.json()
          }
        }
        )
        .then((result) => {
          setAllSpots(result.data);
        })
        .catch((error) => console.error(error));
  }, [spotId]);

  useEffect(() => {
    const localStorageData = JSON.parse(localStorage.getItem("doctor"));
    fetch(Base_url + getSicknesses, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorageData.token}`,
      },
    })
      .then((res) =>{
        console.log(res ,"res");
        if (res.status == 401) {
          localStorage.setItem("doctor",null);
          navigate("/");
          return;
        }else{
        return res.json()
        }
      }
      )
      .then((result) => {
        setSickness(result.data);
      })

      .catch((error) => console.error(error));
  }, []);

  const setBodyPhoto = (userGender, bodySide) => {
    console.log(userGender);
    if (userGender === "Female" || userGender === "Other") {
      if (bodySide === "front") {
        setBodyImage(woman_front);
      }else{
        setBodyImage(woman_back);
      }
    } else {
      if (bodySide === "front") {
        setBodyImage(man_front);
      }else{
        setBodyImage(man_back);
      }
    }
  };

  const registerNewComment = (event) => {

    const newComment = {
      comment: currentComment,
    };
    {
      const localStorageData = JSON.parse(localStorage.getItem("doctor"));
      if (currentImg)
        fetch(
          `https://skinmind.it/api/v2/addComment.php?spotDataId=${currentImg.id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorageData.token}`,
            },
            body: JSON.stringify(newComment),
          }
        )
          .then((res) =>{
            console.log(res ,"res");
            if (res.status == 401) {
              localStorage.setItem("doctor",null);
              navigate("/");
              return;
            }else{
            return res.json()
            }
          }
          )
          .then((result) => {
            var dick =allSpots;
            dick[currentImgIndex].comment = currentComment;
            setAllSpots(dick);
            if (result.status == 1) {
              if (currentImg.comment==="" || currentImg.comment===undefined){
                swal({
                  title: "comment has been added",
                  icon: "success",
                  buttons: "confirm",
                });
              }else{
                swal({
                  title: "comment has been edited",
                  icon: "success",
                  buttons: "confirm",
                });
              }
            }
          });
    }
  };

  const imageClick = (currentImage,index) => {
    setCurrentComment("");
    setCurrentImg(currentImage);
    setCurrentImgIndex(index);
    setCurrentComment(currentImage.comment===undefined?"":currentImage.comment);
  };

  return (
      <>
        <div
            className="bg-cyan-100 h-svh overflow-y-scroll overflow-x-hidden"
            // onClickCapture={() => setAddSpotCheck("close")}
        >
          <div className="container mx-auto">
            <DoctorTopbar profile={JSON.parse(localStorage.getItem("doctorProfile"))}/>
            <div
                dir="ltr"
                className="main-content grid grid-cols-12 gap-10 justify-center"
            >
              <div
                  dir="ltr"
                  className="h-max side-bar bg-emerald-100 col-start-2 col-end-12 sm:col-start-3 sm:col-end-11 md:col-start-4 md:col-end-10 xl:col-span-3 lg:col-span-4 min-w-[300px] grid border-dashed border border-green-600 rounded-xl auto-rows-min gap-2"
              >
                <div className="user-info row-start-1 flex row-end-2 justify-start items-center gap-10 p-5">
                  <img
                      alt="user image"
                      src={`${
                          userProfile.image != ""
                              ? userProfile.image
                              : "https://th.bing.com/th/id/OIP.UY0H6jNLhhjKymJWT6HsPwHaHa?rs=1&pid=ImgDetMain"
                      }`}
                      className="object-cover rounded-full border border-solid w-32 h-32"
                  />
                  <div className="username text-center font-bold text-5xl text-blue-950">
                    {userProfile.firstname} {userProfile.lastname}
                  </div>
                </div>
                <div className="image-type grid grid-cols-4 p-4 gap-1 space-y-1 text-center">
                  <div className="image-type-title col-span-4 text-3xl font-bold text-start">
                    Image type
                  </div>
                  <div className="button-one border border-solid col-start-1 col-end-3 rounded-lg cursor-pointer">
                    Clinical
                  </div>
                  <div className="button-two border border-solid col-start-3 col-end-5 rounded-lg cursor-pointer">
                    Dermoscopic
                  </div>
                </div>
                <div className="image-tag grid grid-cols-2 p-4 gap-1 space-y-1 text-center">
                  <div className="image-type-title col-span-2 text-3xl font-bold text-start">
                    Image Tag
                  </div>
                  <div className="button-one border col-span-2 border-solid rounded-lg cursor-pointer text-start py-1 px-2 font-bold">
                    Doctor-check
                  </div>
                </div>
                <div className="image-Date p-4 gap-1 space-y-1 text-center flex  flex-wrap justify-center">
                  <div className="image-type-title text-3xl font-bold text-center">
                    Image Date
                  </div>
                  <div className="border border-solid p-4 rounded-3xl space-y-4">
                    <div className="w-full">
                      Start Time :
                      <DatePicker
                          value={startDate}
                          onChange={setStartDate}
                          calendar={persian}
                          locale={persian_fa}
                          containerClassName="calender"
                          placeholder="YYYY/MM/DD"

                      />
                    </div>
                    <div className="w-full">
                      End Time :
                      <DatePicker
                          value={endDate}
                          onChange={setEndDate}
                          calendar={persian}
                          containerClassName="calender"
                          locale={persian_fa}
                          placeholder="YYYY/MM/DD"
                      />
                    </div>
                  </div>
                </div>

                <div className="justify-center text-center">
                  <div
                      style={{ backgroundColor: "white" }}
                      className={`body-image min-w-[194px] h-[500px] inline-block relative top-0`}
                  >
                    <ImageComponent
                        onShowControls = {(which)=>{
                          setAddSpotCheck(which);
                          setClickedSpot(null);
                          setSpotId(null);
                          setEndDate(null);
                          setStartDate(null);
                        }}
                        setCurrentXAndY = {(data)=>{
                          setCurrentXAndY(data);
                        }}
                        bodyImage={bodyImage}/>
                    {bodySpots.map((spot) => (
                        <div
                            className="cursor-pointer"
                            id={spot.spotId}
                            onClick={(event) => spotClicked(spot, event)}
                            key={spot.spotId}
                        >
                          <i
                              className={`fa-solid fa-xmark rounded-full text-emerald-300 hover:bg-red-500 hover:text-red-200 w-5 h-5 ${
                                  clickedSpot === spot.spotId && addSpotCheck==="spotClicked"
                                      ? "bg-red-500 text-red-200"
                                      : " bg-emerald-600"
                              }`}
                              id={spot.spotId}
                              onClick={() => ""}
                              style={{
                                position: "absolute",
                                top: spot.y - 2.5,
                                left: spot.x - 2.5,
                              }}
                          ></i>
                        </div>
                    ))}
                  </div>
                </div>
                <div className="body-direction grid grid-cols-5 p-4 gap-3 text-center">
                  <div

                      className={`button-one col-start-2 col-end-3 border border-solid p-2 rounded-lg ${
                          bodySide === "front" ? "bg-gray-400" : "bg-white"
                      }`}
                      style={{ cursor: "pointer",
                        color:bodySide==="front"?"#38628c":"#B338628C",

                        backgroundColor:bodySide==="front"?"#B3CCD8":"white"}}
                      onClick={() => {
                        if (bodySide === "back") {
                          onSideClick("front");
                        }
                      }}
                  >
                    Front
                  </div>
                  <div
                      className={`button-one border border-solid col-start-4 col-end-5 p-2 rounded-lg ${
                          bodySide === "back" ? "bg-gray-400" : "bg-white"
                      }`}
                      style={{ cursor: "pointer",
                        color:bodySide==="back"?"#38628c":"#B338628C",
                        backgroundColor:bodySide==="back"?"#B3CCD8":"white"}}
                      onClick={() => {
                        if (bodySide === "front") {
                          onSideClick("back");
                        }
                      }}
                  >
                    Back
                  </div>
                </div>
                {
                  addSpotCheck == "open"?
                      <div
                      className={`add-spot grid grid-cols-2 p-4 gap-3 text-center`}>
                    <div>
                      <input
                          type="file"
                          accept="image/*"
                          ref={fileInputRef}
                          style={{ display: 'none' }} // Hide the input visually
                          onChange={handleFileChange}
                      />
                      <div
                          className="button-one hover:scale-[1.02] transition-all text-lg col-span-1 bg-green-300 border border-solid p-2 rounded-lg gap-3 cursor-pointer items-center flex justify-center"
                          onClick={handleButtonClick}
                      >
        <span className="text-5xl text-green-800">
          <i className="fa-solid fa-camera"></i>
        </span>
                        ADD SPOT WITH PHOTO
                      </div>
                    </div>
                    <div
                        onClick={()=>{
                          insertNewSpot(false);
                        }}
                        className="hover:scale-[1.02] transition-all text-2xl button-one border border-solid bg-green-300 items-center flex justify-center gap-7 col-span-1 p-2 rounded-lg cursor-pointer">
                      ADD SPOT
                    </div>
                  </div>:addSpotCheck == "spotClicked"?
                      <div
                          style={{justifyContent:"center",alignItems:"center",display:"flex"}}
                          className={`add-spot grid grid-cols-2 p-4 gap-3 text-center`}>
                        <div>
                          <input
                              type="file"
                              accept="image/*"
                              ref={fileInputRef}
                              style={{ display: 'none' }} // Hide the input visually
                              onChange={handleFileChange}
                          />
                          <div
                              className="button-one hover:scale-[1.02] transition-all text-lg col-span-1 bg-green-300 border border-solid p-2 rounded-lg gap-3 cursor-pointer items-center flex justify-center"
                              onClick={handleButtonClick}
                          >
                            <span className="text-5xl text-green-800">
                              <i className="fa-solid fa-camera"></i>
                            </span>
                            ADD PHOTO TO SPOT
                          </div>
                        </div>
                      </div>:
                      <div style={{height:"70px"}} className={`add-spot grid grid-cols-2 p-4 gap-3 text-center `}></div>
                }

              </div>
              <div
                  dir="ltr"
                  className="main col-span-8 lg:col-span-8 col-start-2 col-end-12 sm:col-start-3 sm:col-end-11 md:col-start-4 md:col-end-10 grid-rows-4 auto-rows-auto"
              >
                <div className="checkup-title font-bold text-6xl p-5 row-span-1 text-cyan-900 mx-5 text-center lg:text-start">
                  Check-Ups
                </div>
                <div
                    className={`item-wrapper row-span-1 bg-emerald-100 rounded-2xl p-3 ${
                        allSpots.length == 0 ? "items-center justify-center" : ""
                    }`}
                >
                  {!isImgShow &&allSpots.length && <CircleSpinner />}
                  {filteredData.map(
                      (spot, index) =>
                          spot.image_url && (
                              <div
                                  key={index}
                                  className="item text-cyan-500 flex flex-wrap gap-3 rounded-2xl"
                              >
                                <img
                                    src={spot.image_url}
                                    alt={`Image ${index + 1}`}
                                    id={JSON.stringify(spot)}
                                    data={spot.id}
                                    onLoad={onImageLoaded}
                                    className="item-image cursor-pointer block"
                                    onClick={() => {
                                      imageClick(spot, index);
                                    }}
                                />
                                <span>{spot.insertTime}</span>
                              </div>
                          )
                  )}
                  {!allSpots.length && (
                      <div className="text-5xl text-red-600">Image Not Found</div>
                  )}
                </div>

                <>
                  <div
                      className={`diagnosis-title font-bold lg:text-start text-center text-6xl p-5 row-span-1 m-5 text-cyan-900 ${
                          currentImg != null ? "block" : "hidden"
                      }`}
                  >
                    Diagnosis result
                  </div>
                  <div
                      className={`checkup-title grid grid-rows-2 grid-cols-5 h-[600px] gap-10 sm:gap-x-10 justify-center items-center text-center ${
                          currentImg.image_url != null ? "block" : "hidden"
                      }`}
                  >
                    <div className="diagnosis-image col-span-5 lg:col-span-2 max-h-[400px] justify-center text-center flex">
                      <img
                          src={currentImg.image_url}
                          className="h-[400px] rounded-full border-[5px] border-solid"
                      />
                    </div>
                    <div
                        className="diagnosis-chart col-span-5 lg:col-span-3"
                        style={{
                          border: "2px solid gray",
                          padding: "1rem",
                          borderRadius: "0.5rem",
                        }}
                    >
                      {currentImg.sickId > 0 && (
                          // <Chart sickness={sickness[currentImg - 1]} />
                          <BarChart sickness={sickness[currentImg.sickId - 1]} />
                      )}
                      {currentImg.sickId < 1 && (
                          <div className="text-5xl text-red-600">
                            Information Not Found
                          </div>
                      )}
                    </div>

                    <div
                        className="comment col-span-5"
                        style={{
                          justifyContent: "start",
                          alignItems: "center",
                          justifySelf: "center",
                        }}
                    >
                      <div action="#" className="gap-10 flex w-full flex-wrap">
                        <div>Doctor's Comment:</div>
                        <div className="comment-form">
                          <input
                              className="comment-form-input rounded-xl w-[300px] sm:w-[400px] p-2"
                              id="comment"
                              type="text"
                              placeholder="comment"
                              value={currentComment}
                              onChange={onInputHandler}
                          />
                        </div>
                        <Button
                            className="p-5 bg-teal-400 rounded-2xl"
                            type="submit"
                            onClick={registerNewComment}
                        >
                          <i className="fas fa-sign-out-alt"></i>
                          <span>
                        {currentComment === undefined || currentComment === ""
                            ? "Add Comment"
                            : "Edit Comment"}
                      </span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block absolute top-0 right-0 w-[100px] h-full bg-cyan-500 opacity-40 blur-[120px] rounded-full" />
        <div className="hidden lg:block absolute top-0 left-0 w-[100px] h-full bg-cyan-500 opacity-40 blur-[120px] rounded-full" />
      </>
  );

}
